.rarity {
  width: 100%;
  /* height: auto; */
  padding: 10px;
  display: inline;
}

.main-container {
  width: 1100px;
  margin: 0 auto;
}

.team {
  text-align: center;
}

.about-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  padding-bottom: 30px;
  padding-top: 50px;
  gap: 30px;
  margin: 0 auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  justify-content: space-evenly;
  background-color: #f0ead6;
}

thead,
tbody {
  text-align: left;
  padding: 10px;
}

@media screen and (max-width: 1100px) {
  .main-container {
    width: 95%;
  }
}

@media (max-width: 699px) {
  .rarity {
    display: none;
  }
}
