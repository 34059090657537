:root {
  --mainColor: #eaeaea;
  --secondaryColor: #fff;

  --borderColor: #c1c1c1;

  --mainText: black;
  --secondaryText: #3a3a3a;

  --themeDotBorder: #24292e;

  --previewBg: rgb(251, 249, 243, 0.8);
  --previewShadow: #f0ead6;

  --buttonColor: black;
  --red: red;

  --success-color: #2ecc71;
  --error-color: #e74c3c;
  --timeline: #c1c1c1;
/* minting css */
  --primary: #ffd04d;
  --background: #1f1579;
  --primary-text: #1a1a1a;
  --secondary: #f8f8f8;
  /* --secondary-text: #424242; */
  --accent-text: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  color: var(--mainText);
  font-family: "Russo One", sans-serif;
  font-weight: 500;
  /* text-align: "center"; */
}
th,
td,
p,
li,
span,
label,
input,
textarea {
  color: var(--secondaryText);
  font-family: "Roboto Mono", monospace;
  font-size: 17px;
}
ul {
  list-style: none;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}
a {
  text-decoration: none;
  font-size: 17px;
  color: #17a2b8;
}

.s1 {
  background-color: var(--mainColor);
  /* border-bottom: 0px solid var(--borderColor); */
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 20px;
}

.s2 {
  background-color: var(--secondaryColor);
  /* border-bottom: 0px solid var(--borderColor); */
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 20px;
}

.greeting-wrapper {
  display: grid;
  text-align: center;
  align-content: center;
  min-height: 10em;
}
.main-container {
  width: 1100px;
  margin: 0 auto;
}
li,
.vertical-timeline-element-title {
  text-align: left;
}

.about-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  /* padding-bottom: 30px; */
  padding-top: 50px;
  gap: 30px;
  margin: 0 auto;
}
div.about-wrapper {
  padding-top: 30px;
}
.thumbnail2{
  width: 100%;
  transition: transform 0.1s;
  border-radius: 15px;
}

.social-link {
  padding-top: 90px;
  align-content: left;
  text-align: left;
}

.team {
  text-align: center;
  font-size: 3vmax;
}
.teamtitle {
  text-align: center;
}
.roadmap {
  text-align: left;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  justify-content: space-evenly;
  background-color: #f0ead6;
}

th,
td {
  text-align: left;
  padding: 10px;
}



.footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, 220px);
  gap: 20px;
  justify-content: center;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 80px;
  color: var(--mainText);
}

.copyright {
  display: grid;
  grid-template-columns: repeat(auto-fit, 20%);
  gap: 0px;
  justify-content: center;
  text-align: center;
  opacity: 0.8;
  /* padding-bottom: 60px; */
  /* color: var(--mainText); */
}

.partner img {
  padding-bottom: 20px;
  width: 170px;
  /* height: 130px; */
}
.partner a {
  display: inline-flex;
  padding: 0 20px 40px 40px;
}

@media (max-width: 360px) {
  .partner img {
    padding-bottom: 20px;
    width: 10px;
    /* height: 100px; */
  }
  .partner a {
    flex-direction: column;
  }
}

@media screen and (max-width: 1100px) {
  .main-container {
    width: 95%;
  }
}

@media screen and (max-width: 550px) {
  .social-link {
    padding-top: 50px;
  }
}
/*  */
@media screen and (max-width: 800px) {
  .intro-wrapper {
    grid-template-columns: 1fr;
    grid-template-areas:
      "nav-wrapper"
      "left-column"
      "right-column";
  }
  .right-column {
    justify-content: center;
  }
}

@media screen and (min-width: 630px) {
  h1 {
    font-size: 80px;
  }
}
/*  */
@media screen and (max-width: 550px) {
  h1,
  h2 {
    font-size: 30px;
  }
  .greeting-wrapper,
  .social-links {
    font-size: 12px;
    padding: 20px 0 20px;
  }
  .verify {
    font-size: 13px;
  }
  .social-link {
    padding-top: 50px;
  }
  .partners{
    display: none;
  }
}
