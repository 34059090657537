:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
  =============== 
  Global Styles
  ===============
  */

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  /* background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  border-bottom: 1px solid #c1c1c1; */
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: 0.1rem;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: hsl(210, 22%, 49%);
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

/*
  =============== 
  Navbar
  ===============
  */
nav.nav-center{
  background: #fff;
  background: var(--clr-white);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  box-shadow: var(--light-shadow);
}

.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  
}
.nav-toggle {
  font-size: 1.5rem;
  color: hsl(205, 78%, 60%);
  color: var(--clr-primary-5);
  background: transparent;
  border-color: transparent;
  transition: all 0.3s linear;
  transition: var(--transition);
  cursor: pointer;
  
}
.nav-toggle:hover {
  color: hsl(205, 86%, 17%);
  color: var(--clr-primary-1);
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.links a {
  color: hsl(209, 34%, 30%);
  color: var(--clr-grey-3);
  font-size: 1rem;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  letter-spacing: var(--spacing);
  display: block;
  padding: 0.5rem 1rem;
  transition: all 0.3s linear;
  transition: var(--transition);
}
.links a:hover {
  background: hsl(205, 86%, 81%);
  background: var(--clr-primary-8);
  color: hsl(205, 78%, 60%);
  color: var(--clr-primary-5);
  padding-left: 1.5rem;
}
.social-icons {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  
}
.social-icons a {
  margin: 0 0.5rem;
  font-size: 1rem;
  color: hsl(205, 78%, 60%);
  color: var(--clr-primary-5);
  transition: all 0.3s linear;
  transition: var(--transition);
}
.social-icons a:hover {
  color: hsl(205, 90%, 76%);
  color: var(--clr-primary-7);
}
.links-container {
  height: 0;
  overflow: hidden;
  transition: all 0.3s linear;
  transition: var(--transition);
}
.logo{
  padding-top: 10px;
}
.show-container {
  height: 10rem;
  
}
@media screen and (min-width: 800px) {
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }
  .nav-header {
    padding: 0;
  }
  .nav-toggle {
    display: none;
  }
  .links-container {
    height: auto !important;
  }
  .links {
    display: flex;
  }
  .links a {
    padding: 0;
    margin: 0 0.5rem;
  }
  .links a:hover {
    padding: 0;
    background: transparent;
  }
}



.slider {
  margin: 0 20px;
  overflow: "hidden";
  padding: 2rem 0;
}

.slider img {
  width: 100%;
  border-radius: 10px;
}

.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
  background-color: white;
}

.custom-dot-list-style button {
  border: none;
  background: rgb(255, 68, 68);
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: rgb(255, 68, 68) !important;
}
ul.react-multi-carousel-dot-list.custom-dot-list-style {
  padding-bottom: 20px;
}

:root {
  --mainColor: #eaeaea;
  --secondaryColor: #fff;

  --borderColor: #c1c1c1;

  --mainText: black;
  --secondaryText: #3a3a3a;

  --themeDotBorder: #24292e;

  --previewBg: rgb(251, 249, 243, 0.8);
  --previewShadow: #f0ead6;

  --buttonColor: black;
  --red: red;

  --success-color: #2ecc71;
  --error-color: #e74c3c;
  --timeline: #c1c1c1;
/* minting css */
  --primary: #ffd04d;
  --background: #1f1579;
  --primary-text: #1a1a1a;
  --secondary: #f8f8f8;
  /* --secondary-text: #424242; */
  --accent-text: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  color: black;
  color: var(--mainText);
  font-family: "Russo One", sans-serif;
  font-weight: 500;
  /* text-align: "center"; */
}
th,
td,
p,
li,
span,
label,
input,
textarea {
  color: #3a3a3a;
  color: var(--secondaryText);
  font-family: "Roboto Mono", monospace;
  font-size: 17px;
}
ul {
  list-style: none;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}
a {
  text-decoration: none;
  font-size: 17px;
  color: #17a2b8;
}

.s1 {
  background-color: #eaeaea;
  background-color: var(--mainColor);
  /* border-bottom: 0px solid var(--borderColor); */
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 20px;
}

.s2 {
  background-color: #fff;
  background-color: var(--secondaryColor);
  /* border-bottom: 0px solid var(--borderColor); */
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 20px;
}

.greeting-wrapper {
  display: grid;
  text-align: center;
  align-content: center;
  min-height: 10em;
}
.main-container {
  width: 1100px;
  margin: 0 auto;
}
li,
.vertical-timeline-element-title {
  text-align: left;
}

.about-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  /* padding-bottom: 30px; */
  padding-top: 50px;
  grid-gap: 30px;
  gap: 30px;
  margin: 0 auto;
}
div.about-wrapper {
  padding-top: 30px;
}
.thumbnail2{
  width: 100%;
  transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
  border-radius: 15px;
}

.social-link {
  padding-top: 90px;
  align-content: left;
  text-align: left;
}

.team {
  text-align: center;
  font-size: 3vmax;
}
.teamtitle {
  text-align: center;
}
.roadmap {
  text-align: left;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  justify-content: space-evenly;
  background-color: #f0ead6;
}

th,
td {
  text-align: left;
  padding: 10px;
}



.footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, 220px);
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 80px;
  color: black;
  color: var(--mainText);
}

.copyright {
  display: grid;
  grid-template-columns: repeat(auto-fit, 20%);
  grid-gap: 0px;
  gap: 0px;
  justify-content: center;
  text-align: center;
  opacity: 0.8;
  /* padding-bottom: 60px; */
  /* color: var(--mainText); */
}

.partner img {
  padding-bottom: 20px;
  width: 170px;
  /* height: 130px; */
}
.partner a {
  display: inline-flex;
  padding: 0 20px 40px 40px;
}

@media (max-width: 360px) {
  .partner img {
    padding-bottom: 20px;
    width: 10px;
    /* height: 100px; */
  }
  .partner a {
    flex-direction: column;
  }
}

@media screen and (max-width: 1100px) {
  .main-container {
    width: 95%;
  }
}

@media screen and (max-width: 550px) {
  .social-link {
    padding-top: 50px;
  }
}
/*  */
@media screen and (max-width: 800px) {
  .intro-wrapper {
    grid-template-columns: 1fr;
    grid-template-areas:
      "nav-wrapper"
      "left-column"
      "right-column";
  }
  .right-column {
    justify-content: center;
  }
}

@media screen and (min-width: 630px) {
  h1 {
    font-size: 80px;
  }
}
/*  */
@media screen and (max-width: 550px) {
  h1,
  h2 {
    font-size: 30px;
  }
  .greeting-wrapper,
  .social-links {
    font-size: 12px;
    padding: 20px 0 20px;
  }
  .verify {
    font-size: 13px;
  }
  .social-link {
    padding-top: 50px;
  }
  .partners{
    display: none;
  }
}

.glitch-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.glitch {
  color: black;
  font-size: 150px;
  text-transform: upercase;
  position: relative;
  display: inline-block;
}
.glitch::before,
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.glitch::before {
  left: 2px;
  text-shadow: -2px 0 #49fc00;
  clip: rect(24px, 550px, 90px, 0);
  -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
}
.glitch::after {
  left: -2px;
  text-shadow: -2px 0 #b300fc;
  clip: rect(85px, 550px, 140px, 0);
  -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
  animation: glitch-anim 2.5s infinite linear alternate-reverse;
}
@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }
  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }
  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }
  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }
  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }
  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }
  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }
  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }
  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }
  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }
  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }
  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }
  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }
  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }
  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }
  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }
  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }
  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }
  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }
  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }
  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }
  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }
  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }
  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }
  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}
@keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }
  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }
  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }
  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }
  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }
  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }
  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }
  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }
  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }
  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }
  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }
  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }
  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }
  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }
  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }
  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }
  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }
  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }
  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }
  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }
  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }
  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }
  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }
  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }
  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}
@-webkit-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }
  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }
  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }
  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }
  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }
  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }
  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }
  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }
  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }
  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }
  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }
  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }
  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }
  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }
  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }
  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }
  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }
  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }
  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }
  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }
  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }
  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }
  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }
  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }
  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }
  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }
  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }
  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }
  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}
@keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }
  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }
  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }
  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }
  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }
  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }
  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }
  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }
  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }
  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }
  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }
  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }
  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }
  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }
  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }
  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }
  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }
  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }
  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }
  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }
  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }
  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }
  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }
  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }
  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }
  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }
  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }
  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }
  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

.rarity {
  width: 100%;
  /* height: auto; */
  padding: 10px;
  display: inline;
}

.main-container {
  width: 1100px;
  margin: 0 auto;
}

.team {
  text-align: center;
}

.about-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  padding-bottom: 30px;
  padding-top: 50px;
  grid-gap: 30px;
  gap: 30px;
  margin: 0 auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  justify-content: space-evenly;
  background-color: #f0ead6;
}

thead,
tbody {
  text-align: left;
  padding: 10px;
}

@media screen and (max-width: 1100px) {
  .main-container {
    width: 95%;
  }
}

@media (max-width: 699px) {
  .rarity {
    display: none;
  }
}

.vertical-timeline {
  width: 95%;
  max-width: 1170px;
  margin: 2em auto;
  position: relative;
  padding: 2em 0;
}
.vertical-timeline::after {
  /* clearfix */
  content: "";
  display: table;
  clear: both;
}

.vertical-timeline::before {
  /* this is the vertical line */
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: white;
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline.vertical-timeline--two-columns {
    margin-top: 3em;
    margin-bottom: 3em;
    width: 90%;
  }
  .vertical-timeline.vertical-timeline--two-columns:before {
    left: 50%;
    margin-left: -2px;
  }
}

.vertical-timeline-element {
  position: relative;
  margin: 2em 0;
}
.vertical-timeline-element > div {
  min-height: 1px;
}
.vertical-timeline-element:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-element:first-child {
  margin-top: 0;
}
.vertical-timeline-element:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline-element {
    margin: 4em 0;
  }
  .vertical-timeline-element:first-child {
    margin-top: 0;
  }
  .vertical-timeline-element:last-child {
    margin-bottom: 0;
  }
}

.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* box-shadow: 2px 2px 3px rgba(153, 153, 153, 1); */

  box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.vertical-timeline-element-icon svg {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
  }
}

/* Icon animations */
.vertical-timeline-element-icon {
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-icon.is-hidden {
  visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-1 0.6s;
  animation: cd-bounce-1 0.6s;
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.vertical-timeline-element-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 8px 24px 0 rgba(86, 91, 115, 0.15);
  box-sizing: content-box;
}
.vertical-timeline-element--no-children .vertical-timeline-element-content {
  background: transparent;
  box-shadow: none;
}
.vertical-timeline-element-content:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-element-content h2 {
  color: #303e49;
}
.vertical-timeline-element-content p,
.vertical-timeline-element-content .vertical-timeline-element-date {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 500;
  color: #333;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
  display: inline-block;
}
.vertical-timeline-element-content p {
  margin: 1em 0 0;
  line-height: 1.6;
}

.vertical-timeline-element-title {
  margin: 0;
}

.vertical-timeline-element-subtitle {
  margin: 0;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  float: left;
  padding: 0.8em 0;
  opacity: 0.7;
}
.vertical-timeline-element-content::before {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}
.vertical-timeline-element--no-children
  .vertical-timeline-element-content::before {
  display: none;
}
@media only screen and (min-width: 768px) {
  .vertical-timeline-element-content h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .vertical-timeline-element-content p {
    font-size: 16px;
    font-size: 1rem;
  }
  .vertical-timeline-element-content .vertical-timeline-element-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-content {
    margin-left: 0;
    padding: 1.5em;
    width: 40%;
  }
  .vertical-timeline--two-columns .vertical-timeline-element-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }

  .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    position: absolute;
    width: 100%;
    left: 124%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem;
  }

  .vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content,
  .vertical-timeline--two-columns
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content {
    float: right;
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content::before,
  .vertical-timeline--two-columns
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }

  .vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content
    .vertical-timeline-element-date,
  .vertical-timeline--two-columns
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    left: auto;
    right: 124%;
    text-align: right;
  }
}

/* Box animations */
.vertical-timeline--animate .vertical-timeline-element-content.is-hidden {
  visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-2 0.6s;
  animation: cd-bounce-2 0.6s;
}

@media only screen and (min-width: 1170px) {
  /* inverse bounce effect on even content blocks */
  .vertical-timeline--two-columns.vertical-timeline--animate
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content.bounce-in,
  .vertical-timeline--two-columns.vertical-timeline--animate
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}

@media only screen and (max-width: 1169px) {
  /* .vertical-timeline--animate */
  .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

